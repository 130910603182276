<template>
	<section>
		<div
			:style="{
				left: `${props.element.position.x * workflowConfig.gapWidth - 1 + workflowConfig.boxWidth / 2}px`,
				top: `${
					props.element.position.y * (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) +
					(workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.boxHeight) +
					(workflowStore.showDetail ? -6 : 14)
				}px`,
				position: 'absolute',
				'z-index': 100,
			}"
			class="flex cursor-help"
			@mouseover="hovering = true"
			@mouseleave="hovering = false"
			@touchstart="hovering = true"
			@touchend="hovering = false"
		>
			<FontAwesomeIcon :icon="faCircleInfo" class="shrink-0 h-3 w-3 rounded-xl bg-white shadow-md pointer-events-none select-none" aria-hidden="true" />
		</div>
		<div
			v-show="hovering"
			class="left-4 -top-2.5 flex flex-col justify-center items-center rounded shadow-lg bg-white border border-gray-100 py-1 px-2"
			:style="{
				left: `${props.element.position.x * workflowConfig.gapWidth + 15 + workflowConfig.boxWidth / 2}px`,
				top: `${
					props.element.position.y * (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight) +
					(workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.boxHeight) +
					(workflowStore.showDetail ? -10 : 10)
				}px`,
				position: 'absolute',
				'z-index': 110,
			}"
		>
			<table>
				<tr>
					<th class="flex justify-end whitespace-nowrap">Catch all:</th>
					<td class="pl-1 whitespace-nowrap">{{ element?.branch?.catch_all ? 'Yes' : 'No' }}</td>
				</tr>
				<tr v-if="!element.branch?.catch_all">
					<th class="flex justify-end whitespace-nowrap">Variable:</th>
					<td class="pl-1 whitespace-nowrap">{{ element?.branch?.value_variable ?? '' }}</td>
				</tr>
				<tr v-if="!element.branch?.catch_all">
					<th class="flex justify-end whitespace-nowrap">Resolve by:</th>
					<td class="pl-1 whitespace-nowrap">{{ element?.branch?.selector ?? '' }}</td>
				</tr>
				<tr v-if="!element.branch?.catch_all">
					<th class="flex justify-end whitespace-nowrap">Value:</th>
					<td class="pl-1 whitespace-nowrap">{{ element?.branch?.selector == 'STATIC' ? element?.branch?.value_static ?? '' : element?.branch?.value_expression ?? '' }}</td>
				</tr>
				<tr v-if="element.branch?.notes">
					<th class="flex justify-end whitespace-nowrap">Notes:</th>
					<td class="pl-1">
						<pre>{{ element?.branch?.notes ?? '' }}</pre>
					</td>
				</tr>
			</table>
		</div>
	</section>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import { defineProps, ref } from 'vue';
	import { useWorkflowStore } from '../store';
	import { WorkflowSchema } from '@/types/workflow';
	import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

	const workflowStore = useWorkflowStore();

	interface Props {
		element: WorkflowSchema;
		elementAddress: string;
		elementIndex: number;
	}

	const props = defineProps<Props>();

	const hovering = ref(false);
</script>
