<template>
	<div
		class="workflow-element flex justify-center items-center flex-col branch"
		:style="{
			width: `${workflowConfig.gapWidth}px`,
			height: `${workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight}px`,
			position: 'absolute',
			'z-index': 100,
			left: `${element.position.x * workflowConfig.gapWidth}px`,
			top: `${element.position.y * (workflowStore.showDetail ? workflowConfig.exGapHeight : workflowConfig.gapHeight)}px`,
		}"
		@click="onClick"
	>
		<VConnector v-if="connectorView" :length="0.5" :start-y="0.5" :start-x="0.5" :direction="false" />
		<BranchContent :template="element.template" :element="element" :element-address="elementAddress" :element-index="elementIndex" />
		<VConnector v-if="connectorView" :length="0.5" :start-y="0.5" :start-x="0.5" :direction="true" />
	</div>
</template>

<script setup lang="ts">
	import { workflowConfig } from '@/config/workflow';
	import VConnector from './VConnector.vue';
	import BranchContent from './BranchContent.vue';
	import { defineProps } from 'vue';
	import { useWorkflowStore } from '../store';
	import { WorkflowSchema } from '@/types/workflow';

	const workflowStore = useWorkflowStore();

	interface Props {
		connectorView: boolean;
		element: WorkflowSchema;
		elementAddress: string;
		elementIndex: number;
	}

	const props = defineProps<Props>();

	const onClick = () => {
		workflowStore.openWorkflowElementEditor(props.elementAddress, props.elementIndex);
	};
</script>
