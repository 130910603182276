<template>
	<div class="flex justify-center items-center flex-col bg-gray">
		<Branch :connector-view="true" :element="element" :element-address="elementAddress" :element-index="elementIndex" />
		<div class="flex">
			<Connector :source="getFirstChildPosition" :target="getLastChildPosition" />
			<StepView
				v-for="(branchElement, branchIndex) in (props.element && props.element.children) as WorkflowSchema[]"
				:key="branchElement.id"
				:connector-view="true"
				:element="branchElement"
				:branch-length="getLength"
				:element-address="elementAddress + '[' + elementIndex + '].children[' + branchIndex + '].'"
			/>
			<Connector :source="getEndLineSource" :target="getEndLineTarget" />
		</div>
		<BranchRoot
			v-for="(branchElement, branchIndex) in (props.element && props.element.children) as WorkflowSchema[]"
			:key="branchElement.id"
			:element="branchElement"
			:element-index="branchIndex"
			:element-address="elementAddress + '[' + elementIndex + '].children[' + branchIndex + '].'"
		/>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineAsyncComponent, defineProps, withDefaults } from 'vue';
	import Connector from './Connector.vue';
	import Branch from './Branch.vue';
	import { ElementPosition, WorkflowSchema } from '@/types/workflow';
	import BranchRoot from '@modules/workflow/components/BranchRoot.vue';

	interface Props {
		connectorView: boolean;
		element: WorkflowSchema;
		elementAddress: string;
		elementIndex: number;
	}

	const props = withDefaults(defineProps<Props>(), {
		connectorView: true,
	});

	const StepView = defineAsyncComponent(() => import('./StepView.vue'));

	const getFirstChildPosition = computed<ElementPosition>((): ElementPosition => {
		if (props.element && props.element.children && props.element.children.length > 0) {
			return { x: props.element.children[0].position.x + 0.5, y: props.element.children[0].position.y + 1 };
		}
		return { x: 0, y: 0 };
	});
	const getEndLineSource = computed<ElementPosition>((): ElementPosition => {
		if (props.element && props.element.children && props.element.children.length > 0) {
			return { x: props.element.children[0].position.x + 0.5, y: props.element.children[0].position.y + getLength.value };
		}
		return { x: 0, y: 0 };
	});
	const getLastChildPosition = computed<ElementPosition>((): ElementPosition => {
		if (props.element && props.element.children && props.element.children.length > 0) {
			return { x: props.element.children[props.element.children.length - 1].position.x + 0.5, y: props.element.children[props.element.children.length - 1].position.y + 1 };
		}
		return { x: 0, y: 0 };
	});
	const getEndLineTarget = computed<ElementPosition>((): ElementPosition => {
		if (props.element && props.element.children && props.element.children.length > 0) {
			return { x: props.element.children[props.element.children.length - 1].position.x + 0.5, y: props.element.children[props.element.children.length - 1].position.y + getLength.value };
		}
		return { x: 0, y: 0 };
	});
	const getLength = computed(() => {
		return props.element.length ?? 0;
	});
</script>
